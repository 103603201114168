import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';

import GA4React from 'ga-4-react'; // Google Analytics
import TagManager from 'react-gtm-module'; // Google Tag Manager

// Navigation (used React-Router V6)
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

// Juntos+ client-side pages (login not needed)
import Login from './routes/Login/Login';
import Signin from './routes/Signin/Signin';
import ResultsPublic from './routes/Results/ResultsPublic';
import ForgotPassword from './routes/ForgotPassword/ForgotPassword';
import ForgotEmail from './routes/ForgotEmail/ForgotEmail';
import ResetPassword from './routes/ResetPassword/ResetPassword';
import NewEmail from './routes/NewEmail/NewEmail';
import PhoneNumberConfirmation from './routes/ForgotEmail/PhoneNumberConfirmation';

/* 
Maybe, in the future there will be a landing page to this hub. Now,
it only redirects the user to login
*/
import Landing from './routes/Landing/Landing';

// Juntos+ client-side pages (login needed)
import Home from './routes/Home/Home';
import MyNumbers from './routes/MyNumbers/MyNumbers';
import MyPrizes from './routes/MyPrizes/MyPrizes';
import Promos from './routes/Promos/Promos';
import Results from './routes/Results/Results';
import Profile from './routes/Profile/Profile';

// Admin pages
import HomeAdmin from './routes/admin/Pages/Home';
import LoginAdmin from './routes/admin/Login/Login';
import RegisteredAdmin from './routes/admin/Pages/Registered';
import ElegiblesAdmin from './routes/admin/Pages/Elegibles';
import VouchersAdmin from './routes/admin/Pages/Vouchers';
import ResultsAdmin from './routes/admin/Pages/Results';
import DrawsAdmin from './routes/admin/Pages/Draws';
import ActivityAdmin from './routes/admin/Pages/Activity';
import RegisterAdmin from './routes/admin/Pages/Register';
import AcompanharAdmin from './routes/admin/Pages/Acompanhar';
import EmailChangeRequests from './routes/admin/Pages/EmailChangeRequests';
import { AdminContext } from './contexts/AdminContext';
import AdminLayout from './layout/admin/AdminLayout';

// Initialize GA4
const ga4react = new GA4React(process.env.REACT_APP_GA_TRACKING_ID);
ga4react.initialize().then().catch();

// Initialize GTM
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_TRACKING_ID,
};
TagManager.initialize(tagManagerArgs);

// All routes and pages loaded
function App() {
  return (
    <BrowserRouter>
      <Routes>
        { /* Juntos+ client-side (login not needed) */}
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/esqueci-minha-senha" element={<ForgotPassword />} />
        <Route path="/esqueci-meu-email" element={<ForgotEmail />} />
        <Route path="/confirmar-telefone" element={<PhoneNumberConfirmation />} />
        <Route path="/novo-email" element={<NewEmail />} />
        <Route path="/redefinir-senha" element={<ResetPassword />} />
        <Route path="/resultados-publico" element={<ResultsPublic />} />
        { /* Juntos+ client-side (login needed) */}
        <Route path="/home" element={<Home />} />
        <Route path="/resultados" element={<Results />} />
        <Route path="/numeros" element={<MyNumbers />} />
        <Route path="/premios" element={<MyPrizes />} />
        <Route path="/promos" element={<Promos />} />
        <Route path="/perfil" element={<Profile />} />
        { /* Admin pages */}
        <Route path="/admin" element={<LoginAdmin />} />
        <Route path="/admin" element={<AdminLayout />}>
            <Route path="home" element={<HomeAdmin />} />
            <Route path="cadastrados" element={<RegisteredAdmin />} />
            <Route path="elegiveis" element={<ElegiblesAdmin />} />
            <Route path="numeros" element={<VouchersAdmin />} />
            <Route path="resultados" element={<ResultsAdmin />} />
            <Route path="sorteios" element={<DrawsAdmin />} />
            <Route path="atividade" element={<ActivityAdmin />} />
            <Route path="adicionar" element={<RegisterAdmin />} />
            <Route path="acompanhar" element={<AcompanharAdmin />} />
            <Route path="mudanca-email" element={<EmailChangeRequests />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
